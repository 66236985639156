import * as THREE from 'three'
import React, { useRef, useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { ScrollControls, useScroll, useTexture } from '@react-three/drei'
import { easing } from 'maath'

import './styles.css';

import './util'

const carouselData = [
  { file: '/1.mp4', link: 'https://www.youtube.com/watch?v=tVth-TyDoDY', scale: 1.6, marginY: 0, marginX: 0 },
  { file: '/2.mp4', link: 'https://www.youtube.com/watch?v=tVth-TyDoDY', scale: 2, marginY: 0 , marginX: 0},
  { file: '/3.mp4', link: 'https://www.youtube.com/watch?v=llZ3JsfR50o', scale: 1.8, marginY: 0.1 , marginX: 0},
  { file: '/4.mp4', link: 'https://www.instagram.com/reel/CsOgd-ivpG2/?igsh=eGw0MWkzbGZ0ZWFk', scale: 2, marginY: 0.1 , marginX: 0},
  { file: '/8.mp4', link: 'https://www.youtube.com/watch?v=daH9iAd0Bes', scale: 1.6, marginY: 0.1 , marginX: 0},
  { file: '/20_1.mp4', link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO', scale: 1.8, marginY: 0.1 , marginX: 0},
  { file: '/7.mp4', link: 'https://www.youtube.com/watch?v=daH9iAd0Bes', scale: 1.7, marginY: 0.1 , marginX: 0},
  { file: '/13.mp4', link: 'https://www.youtube.com/watch?v=tVth-TyDoDY', scale: 2, marginY: 0.1 , marginX: 0},
  { file: '/6.mp4', link: 'https://www.youtube.com/watch?v=daH9iAd0Bes', scale: 1.4, marginY: 0.2 , marginX: 0},
  { file: '/26.mp4', link: 'https://www.instagram.com/reel/C2XgyLdMeYS/?igsh=bWF0eHlucm1wbzZ5', scale: 2.4, marginY: 0.1 , marginX: 0},
  { file: '/19_1.mp4', link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO', scale: 1.8, marginY: 0.1 , marginX: 0},
]

const smallCarouselData1 = [
  { file: '/9.mp4', link: 'https://www.youtube.com/watch?v=axziMZNGnLs', scale: 1.6, marginY: 0.1 , marginX: 0},
  { file: '/11.mp4', link: 'https://www.instagram.com/reel/C3QWk4DIFpf/?igsh=MWlkbjV4czh3dXdmZw==', scale: 1.8, marginY: 0.1 , marginX: 0},
  { file: '/12.mp4', link: 'https://www.instagram.com/reel/C3QWk4DIFpf/?igsh=MWlkbjV4czh3dXdmZw==', scale: 1.6, marginY: 0.1 , marginX: 0},
  { file: '/17.mp4', link: 'https://www.instagram.com/reel/C8Hyob4MEC6/?igsh=MXVuYWo3NnhwY2NoNw==', scale: 2.5, marginY: 0.1 , marginX: 0},
  { file: '/21_1.mp4', link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO', scale: 1.9, marginY: -0.2 , marginX: 0},
  { file: '/15.mp4', link: 'https://www.instagram.com/reel/C_YPcz5P9Xs/?igsh=MWNmM3lieDU0ZGxseA==', scale: 1.4, marginY: 0.1 , marginX: 0},
  { file: '/22_1.mp4', link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO', scale: 1.8, marginY: 0.1 , marginX: 0},
  { file: '/27.mp4', link: '', scale: 2.4, marginY: 0.1 , marginX: 0},
  { file: '/23.mp4', link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO', scale: 1.6, marginY: -0.5 , marginX: 0},

]

const smallCarouselData2 = [
  { file: '/16_1.mp4', link: 'https://www.instagram.com/reel/C8Hyob4MEC6/?igsh=MXVuYWo3NnhwY2NoNw==', scale: 2, marginY: 0.2 , marginX: 0},
  { file: '/5.mp4', link: 'https://www.instagram.com/reel/CsOgd-ivpG2/?igsh=eGw0MWkzbGZ0ZWFk', scale: 1.8, marginY: -0.1 , marginX: 0},
  { file: '/14_1.mp4', link: 'https://www.instagram.com/reel/C_YPcz5P9Xs/?igsh=MWNmM3lieDU0ZGxseA==', scale: 1.6, marginY: 0.1 , marginX: -0.1},
  { file: '/25.mp4', link: '', scale: 2.5, marginY: 0.1 , marginX: 0},
  { file: '/18_1.mp4', link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO', scale: 1.6, marginY: 0.1 , marginX: 0},
  { file: '/24.mp4', link: '', scale: 1.8, marginY: 0.2 , marginX: 0},
  { file: '/10.mp4', link: 'https://www.instagram.com/reel/C3QWk4DIFpf/?igsh=MWlkbjV4czh3dXdmZw==', scale: 2, marginY: 0.2 , marginX: 0}

]

function Card({ url, link, scale = 1, marginY = 0, marginX = 0, ...props }) {
  const ref = useRef()
  const videoRef = useRef()
  const [hovered, setHovered] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [videoElement] = useState(() => {
    const video = document.createElement('video')
    video.crossOrigin = 'anonymous'
    video.src = url
    video.autoplay = true
    video.loop = true
    video.muted = true
    video.style.display = 'none'
    document.body.appendChild(video)
    return video
  })

  const [videoTexture] = useState(() => new THREE.VideoTexture(videoElement))

  useEffect(() => {
    if (url && playing) {
      videoElement.play().catch((error) => {
        console.error('Error playing video:', error)
      })
    } else {
      videoElement.pause()
    }

    return () => {
      videoElement.pause()
    }
  }, [url, playing, videoElement])

  useEffect(() => {
    videoTexture.needsUpdate = true
  }, [videoTexture])

  const onPointerOver = (e) => {
    e.stopPropagation() // Остановить событие для других мешей
    setHovered(true)
    setPlaying(true)
  }

  const onPointerOut = (e) => {
    e.stopPropagation() // Остановить событие для других мешей
    setHovered(false)
    setPlaying(false)
  }

  useFrame((state, delta) => {
    easing.damp3(ref.current.scale, hovered ? scale * 0.9 : scale * 0.8, 0.1, delta)
  })

  const updateVideoSize = () => {
    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight
    const cardWidth = 0.9 * scale
    const cardHeight = 0.5 * scale

    const videoAspectRatio = aspectRatio
    const cardAspectRatio = cardWidth / cardHeight

    let videoWidth, videoHeight
    if (videoAspectRatio > cardAspectRatio) {
      videoWidth = cardWidth
      videoHeight = cardWidth / videoAspectRatio
    } else {
      videoWidth = cardHeight * videoAspectRatio
      videoHeight = cardHeight
    }

    videoRef.current.scale.set(videoWidth, videoHeight, 1)
  }

  const handleLink = () => {
    if(link) {
      window.open(link, '_blank');
    }
  }

  useEffect(() => {
    videoElement.addEventListener('loadedmetadata', updateVideoSize)
    return () => {
      videoElement.removeEventListener('loadedmetadata', updateVideoSize)
    }
  }, [videoElement])

  return (
    <group ref={ref} {...props} onClick={handleLink} onPointerOver={onPointerOver} onPointerOut={onPointerOut}>
      <group ref={videoRef}>
        <mesh position={[marginX, marginY, 0.0001]} onPointerOver={onPointerOver} onPointerOut={onPointerOut}> {/* Немного выдвинем вперед */}
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial side={THREE.BackSide} map={videoTexture} />
        </mesh>
        <mesh position={[-0.5 + marginX, marginY, -0.0001]} onPointerOver={onPointerOver} onPointerOut={onPointerOut}> {/* Немного отодвинем назад */}
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial side={THREE.FrontSide} transparent opacity={0.25} map={videoTexture} />
        </mesh>
      </group>
    </group>
  )
}

function Carousel({ radius = 1.4, smallCarouselData = [], scale = 0.8 }) {
  const totalItems = smallCarouselData.length
  const angleStep = (Math.PI * 2) / totalItems
  const distanceFactor = 0.9
  const bend = 0.07 // Примерный параметр изгиба. Вы можете настроить его по своему вкусу

  return (
    <group>
      {smallCarouselData.map(({ file, link, scale: itemScale, marginY, marginX }, i) => {
        const angle = i * angleStep
        const x = Math.sin(angle) * radius * distanceFactor
        const z = Math.cos(angle) * radius * distanceFactor

        // Вычисляем высоту изгиба
        const bendAngle = (angle / (Math.PI * 2)) * (Math.PI * 2 * bend) // Пропорциональная высота изгиба
        const y = Math.sin(bendAngle) * 0.5 // Величина изгиба, можно настроить по своему вкусу

        return (
          <Card
            key={i}
            url={file}
            link={link}
            scale={itemScale * scale}
            position={[x, y, z]}
            rotation={[Math.sin(bendAngle) * 0.2, Math.PI + angle + 0.3, 0]} // Поворот вокруг оси X
            marginY={marginY}
            marginX={marginX}
          />
        )
      })}
    </group>
  )
}

function Rig({ children }) {
  const ref = useRef()
  const scroll = useScroll()

  useFrame((state, delta) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2)
    state.events.update()
    easing.damp3(state.camera.position, [-state.pointer.x * 4, state.pointer.y + 1.5, 10], 0.1, delta)
    state.camera.lookAt(0, 0, 0)
  })

  return <group ref={ref}>{children}</group>
}

export const App = () => {
  return (
    <Canvas background="transparent" camera={{ position: [0, 0, 100], fov: 20 }}>
      <fog attach="fog" args={['#a79', 8.5, 12]} />
      <ScrollControls horizontal pages={4} infinite>
        <Rig>
          <group position={[0, 0.4, 0]}>
            <Carousel smallCarouselData={smallCarouselData2} radius={1.4} />
          </group>
          <group position={[0, -0.2, 0.1]}>
            <Carousel smallCarouselData={carouselData} radius={2.1} />
          </group>
          <group position={[0, -0.9, 0]}>
            <Carousel smallCarouselData={smallCarouselData1} radius={1.5} />
          </group>
        </Rig>
      </ScrollControls>
    </Canvas>
  )
}